import React, { Component } from 'react';
import { Input, Alert, Button, Row, Col, Collapse, Card, Divider } from 'antd';
import {default as vegaEmbed} from 'vega-embed';
import axios from 'axios';

export default class AgentTrainingPlot extends Component {

    constructor(props) {
        super(props);
        this.state = { width: 0, height: 0 };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
      }
      
      componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
      }
      
      componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
      }
      
      updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
      }

    componentWillMount() {
        let url = "https://raw.githubusercontent.com/katnoria/ddpg-reacher/master/data/single/2018-10-25-123253/scores_02nov.txt";
        this.fetchData(url);
    }
    
    fetchData(url) {
        let loading = true
        this.setState({loading});
        // let url = "https://raw.githubusercontent.com/katnoria/ml_examples_for_web/master/data/activations/tanh.json";
        axios.get(url, {crossdomain: true}).then(response => {            
            if (response.data) {
                let data = response.data;                
                let chartData = this.makeChart(data);
                let chartSpec = this.makeChartSpec(chartData);
                if (chartSpec) {
                    this.setState({chartData, chartSpec});
                } else {
                    // show error ?
                }                
            }
            this.setState({loading: false});
        });
    }    

    makeChart(data) {
        if (data) {
            let dataArray = data.split(/\r?\n/);
            return dataArray.map((item, index) => {
                return {
                    x: index,
                    y: item
                }
            })
        }

        return null;
    }

    makeChartSpec(data) {
        return {
            title: "",
            data: {values: data},
            mark: {type: "line"},
            width: this.state['width']/2,
            height: 360,
            autosize: "fit",
            encoding: {
              x: {
                field: "x",
                type: "quantitative",
                axis: {title: "Episode"}
              },
              y: {
                field: "y",
                type: "quantitative",
                axis: {title: "Average score (100 episodes)"}
              }
            }
          }
    }    

    render() {
        let {chartData, chartSpec, loading } = this.state;
        // console.log(dataSpec)
        if (chartSpec) {
            const spec = { actions: false }
            // vegaEmbed(`#trainingChart`, chartData, spec);
            vegaEmbed(`#trainingChart`, chartSpec, spec);
        }
        return (
            <div>
                <div id="trainingChart"  / >
            </div>
        );
    }

}